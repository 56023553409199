var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var module = {
  exports: exports
};

/*! https://mths.be/esrever v0.2.0 by @mathias */
;

(function (root) {
  // Detect free variables `exports`
  var freeExports = exports; // Detect free variable `module`

  var freeModule = true && module.exports == freeExports && module; // Detect free variable `global`, from Node.js or Browserified code,
  // and use it as `root`

  var freeGlobal = typeof _global == "object" && _global;

  if (freeGlobal.global === freeGlobal || freeGlobal.window === freeGlobal) {
    root = freeGlobal;
  }
  /*--------------------------------------------------------------------------*/


  var regexSymbolWithCombiningMarks = /([\0-\u02FF\u0370-\u1AAF\u1B00-\u1DBF\u1E00-\u20CF\u2100-\uD7FF\uE000-\uFE1F\uFE30-\uFFFF]|[\uD800-\uDBFF][\uDC00-\uDFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF])([\u0300-\u036F\u1AB0-\u1AFF\u1DC0-\u1DFF\u20D0-\u20FF\uFE20-\uFE2F]+)/g;
  var regexSurrogatePair = /([\uD800-\uDBFF])([\uDC00-\uDFFF])/g;

  var reverse = function (string) {
    // Step 1: deal with combining marks and astral symbols (surrogate pairs)
    string = string // Swap symbols with their combining marks so the combining marks go first
    .replace(regexSymbolWithCombiningMarks, function ($0, $1, $2) {
      // Reverse the combining marks so they will end up in the same order
      // later on (after another round of reversing)
      return reverse($2) + $1;
    }) // Swap high and low surrogates so the low surrogates go first
    .replace(regexSurrogatePair, "$2$1"); // Step 2: reverse the code units in the string

    var result = "";
    var index = string.length;

    while (index--) {
      result += string.charAt(index);
    }

    return result;
  };
  /*--------------------------------------------------------------------------*/


  var esrever = {
    "version": "0.2.0",
    "reverse": reverse
  }; // Some AMD build optimizers, like r.js, check for specific condition patterns
  // like the following:

  if (freeExports && !freeExports.nodeType) {
    if (freeModule) {
      // in Node.js, io.js, or RingoJS v0.8.0+
      freeModule.exports = esrever;
    } else {
      // in Narwhal or RingoJS v0.7.0-
      for (var key in esrever) {
        esrever.hasOwnProperty(key) && (freeExports[key] = esrever[key]);
      }
    }
  } else {
    // in Rhino or a web browser
    root.esrever = esrever;
  }
})(exports);

export default module.exports;